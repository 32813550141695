<template>
  <div class="tw-p-6 sm:tw-p-10">
    <div class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between">
      <h5 class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0">
        Daftar Pegawai
      </h5>
      <div>
        <Button
          label="Tambah"
          icon="pi pi-plus"
          class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          @click="navigateToCreate"
        />
      </div>
    </div>

    <!-- <form @submit.prevent="doSearch()" class="tw-mb-8 tw-flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="keyword"
          placeholder="Cari disini"
          class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
        />
      </span>
      <Button
        label="Filter"
        type="submit"
        icon="pi pi-align-center"
        iconPos="left"
        class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block md:tw-hidden"
      />
    </form> -->

    <div class="tw-hidden sm:tw-block">
          <DataTable
            :value="barber"
            :paginator="true"
            :rows="10"
            dataKey="id"
            :rowHover="true"
            v-model:filters="filters1"
            filterDisplay="menu"
            :filters="filters1"
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="perPageOptions"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            >

            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters1['global'].value"
                    placeholder="Kata kunci"
                    style="width: 100%"
                  />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Filter"
                  class="p-button-outlined mb-2 ml-2"
                  @click="clearFilter()"
                />
              </div>
            </template>
      
            <template #empty>
              <div
                class="tw-+++flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
              >
                Tidak ada data yang ditemukan
              </div>
            </template>
            <template #loading>
              <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
                Loading records, please wait...
              </div>
            </template>
            <Column header="No." filterField="no" style="min-width: 2rem">
              <template #body="{ data }">
                {{ data.no }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by number"
                />
              </template>
            </Column>
            <Column header="ID." filterField="ID" style="min-width: 2rem">
              <template #body="{ data }">
                {{ data.id }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by ID"
                />
              </template>
            </Column>
            <Column field="name" header="Nama Salon" style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.salon_name }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by name"
                />
              </template>
            </Column>
            <Column field="name" header="Nama Pegawai" style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.name }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by name"
                />
              </template>
            </Column>
            <Column field="position" header="Posisi" style="min-width: 10rem">
              <template #body="{ data }">
                {{ data.position ? data.position : `-` }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by position"
                />
              </template>
            </Column>
            <Column
              field="gender"
              header="Jenis Kelamin"
              style="min-width: 10rem"
            >
              <template #body="{ data }">
                {{ data.gender ? data.gender : `-` }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by gender"
                />
              </template>
            </Column>
            <Column field="status_name" header="Status" style="min-width: 10rem">
              <template #body="{ data }">
                <Tag
                  class="mr-2 status status-warning tw-bg-green-100 tw-text-green-700"
                  value="Aktif"
                  v-if="data.status_name == 'Active'"
                ></Tag>
                <Tag
                  v-else-if="data.status_name == 'Non-Active'"
                  value="Tidak Aktif"
                  class="tw-bg-red-100 tw-text-red-700 tw-py-1 tw-px-2 tw-rounded-full tw-text-xs"
                >
                </Tag>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by status"
                />
              </template>
            </Column>
            <Column field="rating" header="Rating" style="min-width: 10rem">
              <template #body="{ data }">
                <!-- list with blue dot and text Tampil -->
                <Tag
                  class="mr-2 status-warning dot-red"
                  value="Sembunyi"
                  v-if="data.rating == 0"
                ></Tag>
                <Tag
                  class="mr-2 status-warning dot-blue"
                  value="Sembunyi"
                  v-else-if="data.rating == 1"
                ></Tag>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by rating"
                />
              </template>
            </Column>
            <Column
              header="Aksi"
              :showFilterMatchModes="false"
              :filterMenuStyle="{ width: '14rem' }"
              style="min-width: 14rem"
            >
              <template #body="{ data }">
                <a
                  :href="`${data.id}`"
                  class="tw-mr-4"
                  @click.prevent="detailData(data.id)"
                >
                  <i class="pi pi-eye tw-text-lg"></i>
                </a>
                <a
                  :href="`${data.id}`"
                  class="tw-mr-4"
                  @click.prevent="editEmployee(data.id)"
                >
                  <i class="pi pi-pencil tw-text-lg"></i>
                </a>
                <a
                  :href="`${data.id}`"
                  class="tw-mr-4"
                  @click.prevent="openDeleteModal(data.id)"
                >
                  <i class="pi pi-trash tw-text-lg"></i>
                </a>
              </template>
            </Column>
      </DataTable>
    </div>

    <div class="tw-block sm:tw-hidden">
      <div
        class="card tw-p-0 tw-mb-5"
        v-for="(item, i) in card_data"
        :key="`item_${i}`"
      >
        <div class="tw-px-5 tw-py-3">
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize tw-mb-3 tw-pt-1">{{ item.name }}</h6>
            <a href="#" @click.prevent="toogle($event, item)">
              <i class="pi pi-ellipsis-v"></i>
            </a>
            <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
          </div>
          <hr class="mb-3" />
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">
              Posisi
            </h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm ellipsis">
              {{ item.position }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-2">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">
              Jenis Kelamin
            </h6>
            <div v-if="item.gender === 'F'">
              <h6 class="tw-capitalize opacity-60 tw-text-sm ellipsis">
                Perempuan
              </h6>
            </div>
            <div v-else-if="item.gender === 'M'">
              <h6 class="tw-capitalize opacity-60 tw-text-sm ellipsis">
                Laki-laki
              </h6>
            </div>
          </div>
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize opacity-60 tw-text-sm">Status</h6>
            <div>
              <Tag
                class="status status-danger"
                value="Tidak Aktif"
                v-if="item.status === 0"
              ></Tag>
              <Tag
                class="status status-success"
                value="Aktif"
                v-else-if="item.status === 1"
              ></Tag>
            </div>
          </div>
          <div class="tw-flex tw-justify-between tw-mt-2">
            <h6 class="tw-capitalize opacity-60 tw-text-sm">Rating</h6>
            <div>
              <Tag
                class="status-warning dot-red"
                value="Sembunyi"
                v-if="item.rating === 0"
              ></Tag>
              <Tag
                class="status-warning dot-blue"
                value="Tampil"
                v-else-if="item.rating === 1"
              ></Tag>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>

  <Dialog
        v-model:visible="deleteModal"
        :modal="true"
        header="Konfirmasi Hapus"
        :style="{ width: '40%' }"
      >
        <h3>Anda yakin ingin menghapus ini?</h3>

        <div class="flex justify-content-end mt-4">
          <Button
            label="Tidak"
            class="p-button-sm bg-red-600 mr-2"
            @click="deleteModal = false"
          />
          <Button
            label="Ya"
            class="p-button-sm bg-sky-500"
            @click="deleteBarbers()"
          />
        </div>
      </Dialog>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import BarberService from "@/service/BarberService";
export default {
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      barber: [],
      card_data: [],
      filters1: null,
      deleteModal: false,
      deleteId: null,
      menus: [
        {
          label: "Lihat Detail",
          icon: "pi pi-eye",
          command: () => {
            this.detailData(this.current_id);
          },
        },
        {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => {
            this.editEmployee(this.current_id);
          },
        },
        {
          label: "Hapus",
          icon: "pi pi-trash",
          command: () => {
            this.openDeleteModal(this.current_id);
          },
        },
      ],
    };
  },
  barbersService: null,
  created() {
    this.barbersService = new BarberService();
    this.initFilters();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-barber" });
    },

    detailData(id) {
      this.$router.push({ name: "detail-barber", params: { id: id } });
    },

    editEmployee(id) {
      this.$router.push({ name: "edit-barber", params: { id: id } });
    },

    loadData() {
      this.barbersService.getAll().then((response) => {
        if (response.code === 200) {
          this.barber = response.data.barbers;
          const temp = [];
          this.barber.forEach((barbers, idx) => {
            temp.push({ ...barbers, no: idx + 1 });
          });
          this.barber = temp;
          this.card_data = this.barber;
        }
      });
    },

    toogle(event, item) {
      this.current_id = item.id;
      this.dataTemp = item;
      this.$refs.menu.toggle(event);
    },

    openDeleteModal(id) {
      this.deleteModal = true;
      this.deleteId = id;
    },

    async deleteBarbers() {
      this.barbersService.deleteBarber(this.deleteId).then((response) => {
        if (response.code === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: response.message,
            life: 3000,
          });
          this.loadData();
          this.deleteModal = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.message,
            life: 3000,
          });
        }
      });
    },

    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        salon_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        position: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        gender: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        status_name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        hide_rating: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },

    clearFilter() {
      this.initFilters();
    },
  },
};
</script>


<!-- <style scoped lang="scss"></style> -->
<style scoped lang="scss">
.dot-red::before {
  content: "•";
  color: red;
  margin-top: -5px;
  margin-right: 3px;
  font-size: 15px;
}

.dot-blue::before {
  content: "•";
  color: #0071bc;
  margin-top: -5px;
  margin-right: 3px;
  font-size: 20px;
}
</style>


